import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import TasksDetail from './pages/Tasks/Detail';
import TasksHistory from './pages/Tasks/History';
import TasksList from './pages/Tasks/List';
import TasksEditor from './pages/Tasks/Edit';
import TasksNew from './pages/Tasks/New';
import TasksInconsistents from './pages/Tasks/TasksInconsistents';

import RemindersDetail from './pages/Reminders/Detail';
import RemindersList from './pages/Reminders/List';
import RemindersEditor from './pages/Reminders/Edit';
import RemindersNew from './pages/Reminders/New';

import CompaniesDetail from './pages/Companies/Detail';
import CompaniesList from './pages/Companies/List';
import CompaniesEditor from './pages/Companies/Edit';
import CompaniesNew from './pages/Companies/New';

import Profile from './pages/Users/Profile';
import Login from './pages/Users/Login';
import Logout from './pages/Users/Logout';
import ChangePassword from './pages/Users/ChangePassword';

import UsersDetail from './pages/Users/Detail';
import UsersList from './pages/Users/List';
import UsersEditor from './pages/Users/Edit';
import UsersNew from './pages/Users/New';

import AdminUsersDetail from './pages/AdminUsers/Detail';
import AdminUsersList from './pages/AdminUsers/List';
import AdminUsersEditor from './pages/AdminUsers/Edit';
import AdminUsersNew from './pages/AdminUsers/New';

import AdminRepresentCompany from './pages/Admin/RepresentCompany';

import Dashboard from './pages/General/Dashboard';
import NotFound from './pages/General/NotFound';
import Unauthorized from './pages/General/Unauthorized';
import Error from './pages/General/Error';
import ContractGenerate from './pages/Contract/Generate';
import ContractExport from './pages/Contract/Export';

import Reports from './pages/Reports/Reports';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic';

import './index.css';
import './fonts/DancingScript-Regular.ttf';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const alertOptions = {
    position: positions.BOTTOM_LEFT,
    timeout: 5000,
    offset: '80px',
    transition: transitions.SCALE
}

ReactDOM.render((
    <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Router>
            <Switch>
                <Route path="/" exact component={Dashboard} />

                <Route path="/tasks" component={TasksList} />
                <Route path="/task/detail" component={TasksDetail} />
                <Route path="/task/history" component={TasksHistory} />
                <Route path="/task/edit" component={TasksEditor} />
                <Route path="/task/new" component={TasksNew} />
                <Route path="/task/inconsistents" component={TasksInconsistents} />

                <Route path="/reminders" component={RemindersList} />
                <Route path="/reminder/detail" component={RemindersDetail} />
                <Route path="/reminder/edit" component={RemindersEditor} />
                <Route path="/reminder/new" component={RemindersNew} />

                <Route path="/companies" component={CompaniesList} />
                <Route path="/company/detail" component={CompaniesDetail} />
                <Route path="/company/edit" component={CompaniesEditor} />
                <Route path="/company/new" component={CompaniesNew} />

                <Route path="/login" component={Login} />
                <Route path="/profile" component={Profile} />
                <Route path="/logout" component={Logout} />
                <Route path="/changepassword" component={ChangePassword} />

                <Route path="/users" component={UsersList} />
                <Route path="/user/detail" component={UsersDetail} />
                <Route path="/user/edit" component={UsersEditor} />
                <Route path="/user/new" component={UsersNew} />

                <Route path="/admin/users" component={AdminUsersList} />
                <Route path="/admin/user/detail" component={AdminUsersDetail} />
                <Route path="/admin/user/edit" component={AdminUsersEditor} />
                <Route path="/admin/user/new" component={AdminUsersNew} />

                <Route path="/admin/representcompany" component={AdminRepresentCompany} />

                <Route path="/reports" component={Reports} />

                <Route path="/contract/generate" component={ContractGenerate} />
                <Route path="/contract/export" component={ContractExport} />

                <Route path="/error" component={Error} />
                <Route path="/ops" component={NotFound} />
                <Route path="/unauthorized" component={Unauthorized} />
                <Route path="/*">
                    <Redirect to={{ pathname: '/ops' }} />
                </Route>
            </Switch>
        </Router>
    </AlertProvider>
), document.getElementById('root'));

serviceWorker.unregister();
