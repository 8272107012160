export const getIdFromUrlLocationPathname = (pathname) => {
    if (pathname) {
        return pathname.substring(pathname.lastIndexOf('/') + 1);
    }

    return '';
}

export const getRedirectUrlLocation = (location) => {
    if (location && location.search) {
        return location.search.replace('?redirect=', '')
            .replace('redirect=', '')
            .replace('?remove=1', '')
            .replace('remove=1', '');
    }

    return '';
}

export const isRemoveUrlLocation = (location) => {
    if (location && location.search) {
        return location.search?.includes('remove=1');
    }

    return '';
}

export const formatDateTime = (datetime) => {
    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    try {
        if (datetime) {
            datetime = new Date(datetime);
            const date = appendLeadingZeroes(datetime.getDate()) + "/" + appendLeadingZeroes(datetime.getMonth() + 1) + "/" + (datetime.getFullYear() + '').substr(2, 2);
            let time = appendLeadingZeroes(datetime.getHours()) + ":" + appendLeadingZeroes(datetime.getMinutes());
            return date + " " + time;
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatTime = (datetime) => {
    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    try {
        if (datetime) {
            datetime = new Date(datetime);
            return appendLeadingZeroes(datetime.getHours()) + ":" + appendLeadingZeroes(datetime.getMinutes());
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatDateTimeSeconds = (datetime) => {
    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    try {
        if (datetime) {
            datetime = new Date(datetime);
            const date = appendLeadingZeroes(datetime.getDate()) + "/" + appendLeadingZeroes(datetime.getMonth() + 1) + "/" + (datetime.getFullYear() + '').substr(2, 2);
            let time = appendLeadingZeroes(datetime.getHours()) + ":" + appendLeadingZeroes(datetime.getMinutes());
            time = time + ":" + appendLeadingZeroes(datetime.getSeconds());
            return date + " " + time;
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatDate = (datetime) => {

    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    try {
        if (datetime) {
            datetime = new Date(datetime);
            return appendLeadingZeroes(datetime.getDate()) + "/" + appendLeadingZeroes(datetime.getMonth() + 1) + "/" + (datetime.getFullYear() + '').substr(2, 2);
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatDateWithYear = (datetime) => {

    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    try {
        if (datetime) {
            datetime = new Date(datetime);
            return appendLeadingZeroes(datetime.getDate()) + "/" + appendLeadingZeroes(datetime.getMonth() + 1) + "/" + datetime.getFullYear();
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatDateDay = (datetime) => {

    const dias = [
        "Domingo",
        "Segunda-Feira",
        "Terça-Feira",
        "Quarta-Feira",
        "Quinta-Feira",
        "Sexta-Feira",
        "Sábado"
    ];

    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    try {
        if (datetime) {
            datetime = new Date(datetime);
            return appendLeadingZeroes(datetime.getDate())
                + "/" + appendLeadingZeroes(datetime.getMonth() + 1)
                + "/" + (datetime.getFullYear() + '').substr(2, 2)
                + " (" + dias[datetime.getDay()] + ")";
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatMonth = (datetime) => {

    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    try {
        if (datetime) {
            datetime = new Date(datetime);
            return appendLeadingZeroes(datetime.getMonth() + 1) + "/" + (datetime.getFullYear() + '').substr(2, 2);
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatMonthNames = (datetime) => {
    const months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
    ];

    try {
        if (datetime) {
            datetime = new Date(datetime);
            datetime.setDate(datetime.getDate() + 1);
            return (months[datetime.getMonth()]) + "/" + (datetime.getFullYear() + '').substr(2, 2);
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatFullDate = (datetime) => {
    const months = [
        "JANEIRO",
        "FEVEREIRO",
        "MARÇO",
        "ABRIL",
        "MAIO",
        "JUNHO",
        "JULHO",
        "AGOSTO",
        "SETEMBRO",
        "OUTUBRO",
        "NOVEMBRO",
        "DEZEMBRO"
    ];

    try {
        if (datetime) {
            datetime = new Date(datetime);
            return datetime.getDate() + " DE " + months[datetime.getMonth()] + " DE " + datetime.getFullYear();
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const formatDayName = (datetime) => {
    const weekday = [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado"
    ];

    try {
        if (datetime) {
            datetime = new Date(datetime);
            return weekday[datetime.getDay()];
        }
        return '';
    } catch (error) {
        return '';
    }
}

export const copyObject = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

export const formatMoney = (obj) => {
    const value = obj ? obj : 0;
    return parseInt(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
}

export const tratarNomeCampo = (campo) => {
    const campos = [
        { de: 'nome', para: 'Nome' },
        { de: 'tipo', para: 'Tipo' },
        { de: 'dataHoraInicio', para: 'Início' },
        { de: 'dataHoraFim', para: 'Fim' },
        { de: 'telefone', para: 'Telefone' },
        { de: 'observacoes', para: 'Notas' },
        { de: 'status', para: 'Status Compromisso' },
        { de: 'valorTotal', para: 'Valor Total' },
        { de: 'endereco', para: 'Endereço' },
        { de: 'numero', para: 'Número' },
    ];

    for (var i in campos) {
        const mapa = campos[i];
        if (campo === mapa.de) {
            return mapa.para;
        }
    }

    return campo;
}

export const tratarNomeCampoPagamento = (campo) => {
    const campos = [
        { de: 'tipo', para: 'Status' },
        { de: 'valor', para: 'Valor' },
        { de: 'formaPagamento', para: 'Forma de Pagamento' },
        { de: 'data', para: 'Data' },
    ];

    for (var i in campos) {
        const mapa = campos[i];
        if (campo === mapa.de) {
            return mapa.para;
        }
    }

    return campo;
}

export const tratarTiposCampos = (campo, valor) => {

    if (campo.includes('dataHora')) {
        return formatDateTime(valor);
    }

    if (campo.includes('data')) {
        return formatDate(valor);
    }

    if (campo.includes('valor')) {
        return formatMoney(valor);
    }

    return valor;
}

export const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 != val2) {
            return false;
        }
    }
    return true;
}

export const isObject = (object) => {
    return object != null && typeof object === 'object';
}

export const convertConfigToItensSelect = (config) => {
    try {
        return config.split(';').map(obj => {
            const objFormated = obj.trim();
            return { key: objFormated, value: objFormated };
        });
    } catch (error) {
        return undefined;
    }
}

export const convertConfigToBoolean = (config) => {
    try {
        return config === 'true';
    } catch (error) {
        return false;
    }
}