import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import PaymentSession from '../../../components/organisms/PaymentSession';
import DocSession from '../../../components/organisms/DocSession';
import { getTask, removeTask } from '../../../services/Tasks';
import { getDoc } from '../../../services/Docs';
import { getIdFromUrlLocationPathname, isRemoveUrlLocation, formatDateTime, convertConfigToBoolean }
  from '../../../helpers/functions';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBack from '../../../components/atoms/ButtonBack';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import ButtonAction from '../../../components/molecules/ButtonAction';
import Session from '../../../components/atoms/Session';
import InputTextArea from '../../../components/atoms/InputTextArea';
import ModalConfirmRemove from '../../../components/organisms/ModalConfirmRemove';
import { getConfigCompany } from '../../../helpers/authHelper';
import * as fileSaver from "file-saver";
import * as base64ToBlob from "b64-to-blob";


function Detail(props) {

  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState({});
  const [inconsistencias, setInconsistencias] = useState([]);
  const id = getIdFromUrlLocationPathname(props.history.location.pathname);
  const isRemove = isRemoveUrlLocation(props.history.location);
  const alert = useAlert();
  const contratoHabilitado = convertConfigToBoolean(getConfigCompany("CONTRATO_HABILITADO"));

  const [confirmRemoveModalShow, setConfirmRemoveModalShow] = useState(isRemove);

  const remove = async () => {
    setLoading(true);
    const response = await removeTask(id, alert);
    if (response) {
      props.history.push('/tasks');
      alert.success('Compromisso removido com sucesso');
    }
    setLoading(false);
  };

  const downloadFile = async (fileIdent) => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getDoc({ taskId: id, fileIdent: fileIdent }, alert);
      if (result) {
        /*const anchorElement = document.createElement('a');
        anchorElement.href = `data:${result.tipo};base64,${result.fileData}`;
        anchorElement.download = `${result.nome}`;
        anchorElement.click();*/

        const blob = base64ToBlob(result.fileData, result.tipo);
        const file = new File([blob], result.nome, { type: `data:${result.tipo};base64` });
        fileSaver.saveAs(file);
      }
      setLoading(false);
    };

    fetchData();
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getTask(id, alert);
      if (result) {
        setTask(result);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (task && task.inconsistenciasCompromisso && task.inconsistenciasPagamento) {
      setInconsistencias(task.inconsistenciasCompromisso.concat(task.inconsistenciasPagamento));
    }
  }, [task, setInconsistencias]);

  return (
    <Template inconsistencias={inconsistencias} clearInconsFunc={() => setInconsistencias([])}
      inconsistenciasPagamento={task.inconsistenciasPagamento} loading={loading}>
      <PageHeader tittle="Detalhes do compromisso">
        {!task.visivel &&
          <>
            <p style={{ textAlign: 'right', color: 'red' }}>REMOVIDO (Somente histórico)</p>
          </>
        }
      </PageHeader>
      <Row>
        <Col>
          <InputText name="Início"
            value={task.dataHoraInicio ? formatDateTime(task.dataHoraInicio) : ''} disabled />
        </Col>
        <Col>
          <InputText name="Fim"
            value={task.dataHoraFim ? formatDateTime(task.dataHoraFim) : ''} disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText name="Tipo" value={task.tipo || ''} disabled />
        </Col>
        <Col>
          <InputText name="Status Compromisso" value={task.status || ''} disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText name="Nome" value={task.nome || ''} disabled />
        </Col>
        <Col>
          <InputText name="Telefone" value={task.telefone || ''} disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText name="Endereço" value={task.endereco || ''} disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputTextArea name="Notas" value={task.observacoes || ''} disabled />
        </Col>
      </Row>
      <PaymentSession inputs={task} editable={false} />
      <DocSession inputs={task} editable={false} getDocument={(fileIdent) => downloadFile(fileIdent)} />
      <Session name="Informações">
        <Row>
          <Col>
            <InputText name="Criado Por" value={task?.responsavel?.nome || ''} disabled />
          </Col>
          <Col>
            <InputText name="Data Criação"
              value={task.dataHoraCriacao ? formatDateTime(task.dataHoraCriacao) : ''} disabled />
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonAction name="Abrir Histórico" variant="primary"
              onClick={() => { props.history.push(`/task/history/${id}`) }} />
          </Col>
          <>
            {contratoHabilitado && ((task.tipo || '') == 'Casamento') &&
              <Col>
                <ButtonAction name="Gerar contrato" variant="secondary"
                  onClick={() => { props.history.push(`/contract/generate/${id}`) }} />
              </Col>
            }
          </>
        </Row>
      </Session>
      <VerticalSpace />
      <Row>
        <Col style={{ paddingRight: '5px' }}>
          <ButtonBack />
        </Col>
        {task.visivel &&
          <>
            <Col className="col-md-1" style={{ paddingRight: '5px' }}>
              <ButtonSubmit className="center-block" variant="warning"
                onClick={() => { props.history.push(`/task/edit/${id}`) }}>Editar</ButtonSubmit>
            </Col>
            <Col style={{ paddingRight: '5px' }}>
              <ButtonSubmit className="float-right" variant="danger"
                onClick={() => { setConfirmRemoveModalShow(true) }}>Remover</ButtonSubmit>
            </Col>
          </>
        }
      </Row>
      <ModalConfirmRemove show={confirmRemoveModalShow} onHide={() => setConfirmRemoveModalShow(false)}
        onConfirm={() => { remove() }} />
    </Template>
  );
}

export default Detail;