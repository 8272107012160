import React, { useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Container, Button } from 'react-bootstrap';
import ContractView from '../../../components/organisms/ContractView';
import html2pdf from 'html2pdf.js';

function ContractExport(props) {

  const backpage = async () => {
    props.history.push(`/contract/generate/${JSON.stringify(inputs)}`);
  };

  const exportFile = async () => {
    const element = document.getElementById('divToPrint');
    const elementsToHide = document.getElementsByClassName('html2pdf__ignore');
    elementsToHide.forEach((elementToHide) => {
      elementToHide.style.display = 'none';
    });
    await html2pdf(element);
    elementsToHide.forEach((elementToHide) => {
      elementToHide.style.display = 'block';
    });
  };

  const getInputsFromURL = (pathname) => {
    if (pathname) {
      return pathname.substring(pathname.lastIndexOf('contract/export/') + 16);
    }

    return '';
  };

  const encodedInputs = getInputsFromURL(props.history.location.pathname);
  const decodedInputs = decodeURI(encodedInputs);
  const inputs = JSON.parse(decodedInputs);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [inputs]);

  return (
    <Template>
      <Container>
        <Button className="float-left" onClick={() => exportFile()}>Salvar contrato</Button>
        <Button className="float-right" variant="secondary" onClick={() => backpage()}>Voltar</Button>
        <ContractView inputs={inputs} />
      </Container>
    </Template>
  );
}

export default ContractExport;