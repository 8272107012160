import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col, Form } from 'react-bootstrap';
import { registerCompany } from '../../../services/Companies';
import InputText from '../../../components/atoms/InputText';
import useForm from '../../../helpers/formHelper';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import TableConfigurations from '../../../components/atoms/TableConfigurations';
import Session from '../../../components/atoms/Session';

function New(props) {

  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const { inputs, handleInputChange, validateInputs, setInputs } = useForm();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      setInputs({
        ...inputs, configuracoes: [
          { chave: 'TIPOS_COMPROMISSO', valor: 'Bloqueio;Reunião' },
          { chave: 'FORMAS_PAGAMENTO', valor: 'Dinheiro;Cartão;Transferência' },
          { chave: 'PAGAMENTO_HABILITADO', valor: 'false' },
          { chave: 'CONTRATO_HABILITADO', valor: 'false' },
          { chave: 'GROUP_ID_NOTIFICATION', valor: '6523008dfe6675d6ac818a28' },
          { chave: 'FILES_UPLOAD_FOLDER_ID', valor: '1Xikat5FV-n6pDTlbDwOL1UbRwn7oG3Me' }]
      });
      setLoading(false);
    };

    fetchData();
  }, []);

  const addValidationError = (validationError) => {
    let validationsError = validationErrors.slice();
    validationsError.push(validationError);
    setValidationErrors(validationsError);
  }

  const register = async () => {
    setLoading(true);

    let company = {
      nome: inputs.nome,
      configuracoes: inputs.configuracoes
    };

    const response = await registerCompany(company, alert, addValidationError);

    if (response) {
      if (response._id) {
        props.history.push(`/company/detail/${response._id}`);
      } else {
        props.history.push(`/companies`);
      }
      alert.success('Empresa criada com sucesso');
    }
    setLoading(false);
  }

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'createCompany' });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        register();
      }
    }

    setValidated(true);
  };

  return (
    <Template validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
      loading={loading}>
      <PageHeader tittle="Criar Empresa" />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputText id="nome" name="Nome" value={inputs.nome || ''}
              onChange={handleInputChange} />
          </Col>
        </Row>
        <Session name="Configurações">
          <TableConfigurations columns={[{ dataField: 'chave', text: 'Chave' }, { dataField: 'valor', text: 'Valor' }]}
            keyField='chave' data={inputs.configuracoes || []} editable />
        </Session>
        <ButtonBar>
          <ButtonSubmit variant="success" type="submit">Criar</ButtonSubmit>
        </ButtonBar>
      </Form>
    </Template>
  );
}

export default New;