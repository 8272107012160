import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonAction(props) {
    return (
        <>
            <Button {...props}>{props.name}</Button>
        </>
    );
}

export default ButtonAction;