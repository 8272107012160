import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col, Form, Button } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import InputTextArea from '../../../components/atoms/InputTextArea';
import useForm from '../../../helpers/formHelper';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import { getIdFromUrlLocationPathname, formatMoney, formatDateWithYear, formatTime } from '../../../helpers/functions';
import { getTask } from '../../../services/Tasks';

function ContractGenerate(props) {

  const [loading, setLoading] = useState(false);

  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const id = getIdFromUrlLocationPathname(props.history.location.pathname);
  const alert = useAlert();

  const backpage = async () => {
    props.history.push(`/task/detail/${inputs.id}`);
  };

  const getInputsFromURL = (pathname) => {
    if (pathname) {
      return pathname.substring(pathname.lastIndexOf('contract/generate/') + 18);
    }

    return '';
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let inputs = {};
      const encodedInputs = getInputsFromURL(props.history.location.pathname);
      
      if (encodedInputs.indexOf('{') >= 0) {
        const decodedInputs = decodeURI(encodedInputs);
        inputs = JSON.parse(decodedInputs);
      } else {
        const result = await getTask(id, alert);

        if (result) {
          inputs = {
            id: id,
            nome: result.nome,
            telefone: result.telefone,
            valor: formatMoney(result.valorTotal),
            entrada: formatMoney(),
            resto: formatMoney(),
            data: formatDateWithYear(result.dataHoraInicio),
            hora: '00:00',
            horarioinicio: formatTime(result.dataHoraInicio),
            dataentrada: 'no ato da assinatura do contrato',
            servico: 'produção completa da noiva: maquiagem em HD, selagem, preparação da pele, cabelo-penteado, cílios postiços, prova de maquiagem, assessoria na escolha dos acessórios, deslocamento ao local do evento e acompanhamento N1 após a cerimônia com duração de até 2 horas para retirada do véu e retoque de maquiagem'
          };
        }
      }

      setInputs(inputs);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  const register = async () => {
    setLoading(true);
    props.history.push(`/contract/export/${JSON.stringify(inputs)}`);
    setLoading(false);
  };

  const { inputs, handleInputChange, setInputs, validateInputs } = useForm();

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'generate' });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        register();
      }
    }

    setValidated(true);
  };

  return (
    <Template validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
      loading={loading}>
      <PageHeader tittle="Gerar contrato" />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputText id="nome" name="Nome" value={inputs.nome || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="endereco" name="Endereço Pessoal" value={inputs.endereco || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="rg" name="RG" value={inputs.rg || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputText id="cpf" name="CPF" value={inputs.cpf || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="telefone" name="Telefone" value={inputs.telefone || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputText id="email" name="Email" value={inputs.email || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="data" name="Data Evento" value={inputs.data || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputText id="hora" name="Hora Evento" value={inputs.hora || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="horarioinicio" name="Início Produção" value={inputs.horarioinicio || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputText id="horarioinicioextenso" name="I. P. Extenso" value={inputs.horarioinicioextenso || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="local" name="Local Evento" value={inputs.local || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputTextArea id="servico" name="Serviço" value={inputs.servico || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="valor" name="Valor Total" value={inputs.valor || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputText id="valorextenso" name="V. T. Extenso" value={inputs.valorextenso || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="entrada" name="Valor Entrada" value={inputs.entrada || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputText id="entradaextenso" name="V. E. Extenso" value={inputs.entradaextenso || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="resto" name="Valor Restante" value={inputs.resto || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputText id="restoextenso" name="V. R. Extenso" value={inputs.restoextenso || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="dataentrada" name="Data Entrada" value={inputs.dataentrada || ''}
              onChange={handleInputChange} required />
          </Col>
        </Row>
        <Button variant="secondary" size="lg" className="float-left" onClick={() => backpage()}>Voltar</Button>
        <ButtonSubmit variant="success" type="submit">Gerar contrato</ButtonSubmit>
      </Form>
    </Template >
  );
}

export default ContractGenerate;