import React from 'react';
import { formatFullDate } from '../../../helpers/functions';

function ContractView(props) {
  return (
    <div id="divToPrint" className='contract-container'>
      <div style={{ marginLeft: '90px', marginRight: '60px' }}>
        <div style={{ paddingTop: '50px' }}>
          <img src='/img/logo-contract.jpeg' width='160' height='80' className='center-img' />
        </div>
        <div style={{ paddingTop: '10px' }}>
          <p className='contract-title'><b>CONTRATO DE ADESÃO DE
            PRESTAÇÃO DE SERVIÇOS DE MAQUIAGEM E PENTEADO</b></p>
        </div>
        <div style={{ paddingTop: '10px' }}>
          <p className='contract-p-justify'>
            <b>CONTRATADA:</b> ÉRIKA KARLA SANTANA LINS CARDOSO, brasileira, casada, maquiadora e cabeleireira,
            portadora do RG 6.901.860 SDS/PE, inscrita no CPF sob nº 064.230.794-63, residente e domiciliada na
            Avenida Bernardo Vieira de Melo, nº 2087 - Apto. 1401 / CEP: 54.410-010 Piedade – Jaboatão dos Guararapes/PE.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p><b>CONTRATANTE(S):</b> {props.inputs.nome}<br />
            ENDEREÇO: {props.inputs.endereco}<br />
            RG: {props.inputs.rg}<br />
            CPF: {props.inputs.cpf}<br />
            Fone para contato: {props.inputs.telefone}<br />
            E-mail: {props.inputs.email}</p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            As partes acima identificadas têm, entre si, justo e acertado, o presente Contrato de adesão de
            Prestação de Serviços de Maquiagem e Penteado, que será regido pela legislação aplicável, bem como
            pelas cláusulas seguintes e condições descritas no presente instrumento.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p>
            <b>DO OBJETO DO CONTRATO</b>
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 1ª.</b> O presente contrato tem como OBJETO, a prestação, pela CONTRATADA, de serviços de
            MAQUIAGEM e PENTEADO, à CONTRATANTE, para o evento DE CASAMENTO a realizar-se
            no dia <b>{props.inputs.data}</b> às <b>{props.inputs.hora}</b> no (a) {props.inputs.local}.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Parágrafo primeiro:</b> A prestação de serviço do objeto do presente contrato,
            consiste em {props.inputs.servico}.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Parágrafo Segundo:</b> A prova do cabelo e maquiagem que será utilizada no dia do evento deverá ocorrer
            com antecedência mínima de 20 (vinte) dias e máxima de 15 (quinze) dias.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Parágrafo terceiro:</b> Em caso de alteração do local do evento, deverá ser ajustado por termo aditivo
            eventual taxa de deslocamento.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p>
            <b>DO PRAZO</b>
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 2ª.</b> Os serviços a que se refere à cláusula específica serão concluídos na data do evento.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p>
            <b>DO PAGAMENTO</b>
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 3ª.</b> Pela prestação dos serviços acertados, a contratante pagará à contratada a quantia
            de <b>{props.inputs.valor}</b> ({props.inputs.valorextenso}) na conta cujos
            dados seguem abaixo:
          </p>
        </div>
        <div style={{ paddingTop: '5px', marginLeft: '15px' }} className='html2pdf__page-break'>
          <p>Banco Santander<br />
            Agência: 3082 Conta: 01056367-9<br />
            Heitor Soares Cardoso<br />
            Chave PIX (81) 998301031</p>
        </div>
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <div className='contract-linebreak html2pdf__ignore' />
        <div style={{ paddingTop: '50px' }}>
          <img src='/img/logo-contract.jpeg' width='160' height='80' className='center-img' />
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Parágrafo primeiro:</b> No valor acima descrito, está incluso o deslocamento para atendimento na
            região metropolitana de Recife-PE.
          </p>
          <p className='contract-p-justify'>
            <b>Parágrafo segundo:</b> O pagamento se dará da seguinte
            forma: <b>{props.inputs.entrada}</b> ({props.inputs.entradaextenso}) {props.inputs.dataentrada} e
            o saldo remanescente no valor
            de <b>{props.inputs.resto}</b> ({props.inputs.restoextenso}) em até 7 (sete) dias antes do evento.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p>
            <b>DAS OBRIGAÇÕES DA CONTRATADA</b>
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 4ª.</b> A CONTRATADA se compromete a chegar ao local acordado
            às <b>{props.inputs.horarioinicio}h</b> ({props.inputs.horarioinicioextenso}) para
            a prestação dos serviços podendo ter uma tolerância de atraso de 15 (quinze) minutos.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Parágrafo único.</b> A CONTRATADA se obriga a utilizar produtos de boa qualidade, hipoalérgicos,
            dentro do prazo de validade, bem como dirimir dúvidas quanto à utilização dos produtos.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p>
            <b>DAS OBRIGAÇÕES DA CONTRATANTE</b>
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 5ª.</b> A CONTRATANTE deverá fornecer à CONTRATADA condições estruturais que se fizerem necessárias
            para a realização do serviço, com pontos de energia funcionando para a utilização de equipamentos,
            além de disponibilizar mesa para a equipe organizar e instalar os materiais.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Cláusula 6ª.</b>  Caso a produção ultrapasse 4h (quatro horas), a CONTRATANTE deverá fornecer alimentação
            pertinente ao horário durante a prestação de serviço (café da manhã, almoço, jantar, lanche e
            bebidas não alcóolicas) à equipe até finalização do serviço.
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 7ª.</b> É de total responsabilidade da CONTRATANTE eventuais danos causados aos equipamentos e
            membros da equipe de trabalho por parte de seus convidados (as), devendo, para isso, ressarcir os
            danos materiais experimentados pela CONTRATADA e/ou os membros de sua equipe.
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 8ª.</b> As despesas relativas ao uso de energia e água serão custeadas pelo (a) CONTRATANTE.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Cláusula 9ª.</b> O (A) CONTRATANTE ____________________ o uso de imagens da maquiagem e penteado quando
            de sua preparação, bem como do evento, para divulgação em site, Facebook, Instagram, mostruários,
            portfólios, resguardando-se seus direitos de personalidade e imagem, como preceitua o Código Civil
            e legislação afim.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }} className='html2pdf__page-break'>
          <p>
            <b>DA RESCISÃO E DAS MULTAS</b>
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 10ª.</b> O presente contrato poderá ser rescindido e o serviço não prestado pela CONTRATADA por
            descumprimento do pagamento na forma acordada.
          </p>
          <p className='contract-p-justify'>
            <b>Parágrafo único:</b> Por mera liberalidade, poderá a CONTRATADA, em caso de inadimplência, aplicar
            multa de 10% (dez por cento) do valor total do contrato e manter os termos do contrato.
          </p>
        </div>
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <div className='contract-linebreak html2pdf__ignore' />
        <div style={{ paddingTop: '50px' }}>
          <img src='/img/logo-contract.jpeg' width='160' height='80' className='center-img' />
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Cláusula 11ª.</b> No caso de rescisão decorrente de desistência do serviço por ambas as partes, restam
            fixados abaixo os seguintes percentuais a serem pagos à outra parte, conforme quadro abaixo,
            devendo o pagamento ser realizado em até 48h (quarenta e oito horas) após a comunicação:
          </p>
          <div className='contract-table' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <table>
              <tr>
                <td><b>Tempo antes da data do evento</b></td><td><b>Porcentagem (%) do valor total do contrato</b></td>
              </tr>
              <tr>
                <td>Até 9 meses</td><td>30</td>
              </tr>
              <tr>
                <td>Entre 9 e 6 meses</td><td>40</td>
              </tr>
              <tr>
                <td>Entre 6 e 3 meses</td><td>60</td>
              </tr>
              <tr>
                <td>Entre 3 e 1 mês</td><td>80</td>
              </tr>
              <tr>
                <td>Em menos de 1 mês</td><td>100</td>
              </tr>
            </table>
            <br />
          </div>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            <b>Parágrafo único.</b> No caso de cancelamento por motivo de força maior ou caso fortuito do (a) CONTRATANTE
            e/ou CONTRATADA, os valores pagos serão devolvidos integralmente à outra parte.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p>
            <b>CONDIÇÕES GERAIS</b>
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 12ª.</b> O presente contrato passa a ter vigência na data da assinatura pelas partes.
          </p>
          <p className='contract-p-justify'>
            <b>Cláusula 13ª.</b> As partes elegem o foro da comarca de Recife-PE para dirimir quaisquer controvérsias
            oriundas do presente instrumento.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            Por estarem assim justos e contratados, firmam o presente instrumento, em duas vias de igual teor.
          </p>
        </div>
        <div style={{ paddingTop: '5px' }}>
          <p className='contract-p-justify'>
            RECIFE-PE, {formatFullDate(new Date())}.
          </p>
        </div>
        <div style={{ paddingTop: '45px' }}>
          <span style={{ marginLeft: '30px', marginRight: '30px' }}>
            CONTRATANTE:_______________________________________________________________________________
          </span>
          <br />
          <br />
          <img src='/img/sign-contract.jpeg' width='100%' height='100' className='center-img' />
        </div>
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
        <br className='html2pdf__ignore' />
      </div>
    </div>
  );
}

export default ContractView;